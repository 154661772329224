<template>
	<div class="addRelo">
		<div class="sub">
			<div class="content">
				<div class="item" style="height: 60px">
					<span class="itemLabel">头像</span>
					<span class="itemValue" @click="getchooseImage" v-if="baseData.photo != ''">
						<img class="photo" :src="baseData.photo" alt="" srcset="" />
					</span>
					<span v-else class="addImage">
						<img :src="addImage" alt="" srcset="" @click="getchooseImage" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel itemMust">姓名</span><span class="itemValue">
						<el-input class="inputView" size="mini" v-model="baseData.name" placeholder="请输入姓名"></el-input>
						<van-icon name="arrow" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel itemMust">账号</span><span class="itemValue">
						<el-input class="inputView" size="mini" v-model="baseData.username" placeholder="请输入账号"
							@input="usernameInput"></el-input>
						<van-icon name="arrow" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel itemMust">密码</span><span class="itemValue">
						<el-input class="inputView" size="mini" type="password" v-model="baseData.password"
							placeholder="请输入密码"></el-input>
						<van-icon name="arrow" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel itemMust">科室</span><span class="itemValue">
						<el-input class="inputView" size="mini" readonly v-model="baseData.tenantName"
							placeholder="请输入选择科室" @focus="tenantFocus"></el-input>
						<van-icon name="arrow" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel">手机号码</span><span class="itemValue">
						<el-input class="inputView" size="mini" v-model="baseData.phone" placeholder="请输入手机号码">
						</el-input>
						<van-icon name="arrow" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel">厂家</span><span class="itemValue">
						<el-input class="inputView" size="mini" v-model="baseData.company" placeholder="请输入厂家">
						</el-input>
						<van-icon name="arrow" />
					</span>
				</div>
				<div class="item">
					<span class="itemLabel">服务项目</span><span class="itemValue"> 上门维修服务 </span>
				</div>
			</div>
		</div>
		<div class="footer" @click="submit">提交</div>
		<KcCascader ref="kcCascader" v-model="baseData.tenantId" :options="baseOptions.tenantOptions"
			@submit="KcCascaderSubmit" :props="{
        value: 'id',
        label: 'name',
        children: 'children',
      }"></KcCascader>
	</div>
</template>

<script>
	import tenantBaseDataMixin from "@/components/filterPanel/tenantBaseDataMixin.js";
	import photoActionMixin from '@components/photoActionMixin/photoActionMixin'
	export default {
		name: "addRole",
		mixins: [tenantBaseDataMixin, photoActionMixin],
		data() {
			return {
				addImage: require("@/icons/imgs/addImage.png"),
				baseData: {
					name: "",
					username: "",
					phone: "",
					time: "",
					number: "",
					photoUrl: "",
					photoId: "",
					tenantId: "",
					tenantName: "",
					company: "",
				},
				baseOptions: {
					tenantOptions: [],
				},
				userInfo: {},
				publicKey: "",
				userBindRoleList: [],
				roleList: [],
				roleName: "ROLE_REPORT",
			};
		},
		computed: {
			isAdmin() {
				if (
					typeof this.userInfo["roleList"] == "undefined" ||
					this.userInfo["roleList"] == null
				) {
					return false;
				}
				const roleList = this.userInfo["roleList"];
				for (let i = 0; i < roleList.length; i++) {
					const role = roleList[i];
					if (role == "ROLE_ADMIN") {
						return true;
					}
				}
				return false;
			},
		},
		created() {
			this.userInfo = this.$store.getters.userInfo;
			this.getPublicKey();
		},
		mounted() {
			this.getUserBindRole().then((res) => {
				this.getRoleList();
			});
		},
		methods: {
			onChange() {},
			tenantFocus() {
				this.$refs.kcCascader.show();
			},
			KcCascaderSubmit(value, label) {
				this.baseData.tenantName = label.name;
			},
			getPublicKey() {
				this.$api.loginApi
					.getPublicKey({})
					.then((res) => {
						this.publicKey = res.data;
					})
					.catch((res) => {
						this.publicKey = "";
					});
			},
			getchooseImage() {
				this.chooseImage().then((res) => {
					if (res.result == false) {
						this.imageId = ''
						this.$toast.fail('请重新选择图片')
						return
					}
					this.imageId = res.localID
					this.saveImageToServer()
				})
			},
			saveImageToServer() {
				if (typeof localStorage["corpInfo"] != "undefined") {
					try {
						let corpInfo = JSON.parse(localStorage["corpInfo"]);
						this.$showLoading({
							target: ".addImage",
						});
						this.uploadImageToWeChatAndGet(this.imageId, corpInfo)
							.then((res) => {
								if (res.result == false) {
									this.$toast.fail("获取图片失败");
									return;
								}
								let _image =
									res.image.url.replace(
										"http://192.168.1.243:10000",
										window.ipConfig.VUE_APP_BASEURL
									) + "&scala=0.3";
								this.baseData.photoId = res.image.id;
								this.baseData.photo = _image;
								this.$hideLoading({
									target: ".addImage",
								});
							})
							.catch((err) => {
								this.$hideLoading({
									target: ".addImage",
								});
							});
					} catch (e) {
						this.$toast.fail("解析图片失败");
					}
				}
			},
			usernameInput() {
				if (this.baseData.username) {
					const value = this.baseData.username
					var reg = /[\u4e00-\u9fa5]|\s/g
					this.baseData.username = value.replace(reg, '')
				}
			},
			submit() {
				if (this.baseData.username == "" || this.baseData.password == "") {
					this.$toast.fail("请完善信息");
					return;
				}
				this.$dialog.confirm({
					message: `确认添加工程师？`,
					beforeClose: this.addUser,
				});
			},
			addUser(action, done) {
				if (action === "confirm") {
					const bindRole = (userId) => {
						if (this.roleList.length == 0) {
							this.$back();
							return;
						}
						const roleIds = this.roleList[0].id;
						this.$api.userInfo
							.setUserBindingRole({
								userId: userId,
								bindType: 1,
								roleIds: roleIds,
							})
							.then((res) => {
								this.$back();
								done();
							})
							.catch(() => {
								this.$back();
								done();
							});
					};
					const pwd = this.$rsaEncrypt(this.publicKey, this.baseData.password);
					this.$api.userInfo
						.addUser({
							roleName: this.roleName,
							deleted: 0,
							enable: 1,
							password: pwd,
							photo: this.baseData.photoId,
							username: this.baseData.username,
							name: this.baseData.name,
							tenantId: this.baseData.tenantId,
							repairUserInfo: JSON.stringify({
								phone: this.baseData.phone,
								company: this.baseData.company,
							}),
						})
						.then((res) => {
							if (this.isAdmin) {
								// 是admin就不绑定角色了
								this.$back();
							} else {
								// 不是admin 绑定护士角色
								if (this.roleList.length == 0) {
									this.getRoleList().then(() => {
										bindRole(res.data, done);
									});
								} else {
									bindRole(res.data, done);
								}
							}
							this.$toast.success("操作成功");
						})
						.catch((e) => {
							this.$toast.fail(e.data.msg);
							done();
						});
				} else {
					done();
				}
			},
			getUserBindRole() {
				return new Promise((resolve) => {
					this.$api.userInfo
						.RoleListByUser({
							userId: this.userInfo.id,
						})
						.then((res) => {
							this.userBindRoleList = res.data;
							resolve({});
						})
						.catch(() => {
							resolve({});
						});
				});
			},
			getRoleList() {
				return new Promise((resolve) => {
					this.$api.userInfo
						.getRoleList({
							deleted: 0,
							roleName: this.roleName,
						})
						.then((res) => {
							const list = [];
							for (let i = 0; i < res.data.length; i++) {
								const data = res.data[i];
								if (data.enable == 0) {
									continue;
								}
								let shouldAdd = false;
								for (let j = 0; j < this.userBindRoleList.length; j++) {
									const role = this.userBindRoleList[j];
									if (role.roleName.indexOf(data.roleName) >= 0) {
										shouldAdd = true;
										break;
									}
								}
								if (shouldAdd == false) {
									continue;
								}
								list.push(data);
							}

							this.roleList = list;
							resolve({});
						})
						.catch(() => {
							resolve({});
						});
				});
			},
		},
	};
</script>
<style lang='scss' scoped>
	@import "@styles/variables.scss";

	.addRelo {
		height: 100%;
		padding: 10px;

		.sub {
			height: calc(100% - 76px);

			.content {
				background: $background_color2;
				border-radius: 2px;
				padding: 12px;
			}

			.item {
				font-size: 14px;
				height: 38px;
				line-height: 37px;
				display: flex;
				justify-content: space-between;

				.itemLabel {
					color: $color1;
				}

				.itemMust::before {
					content: "*";
					color: #ff3a3a;
					margin-right: 4px;
					font-size: 16px;
				}

				.itemValue {
					color: #c0c4cc;

					.photo {
						width: 48px;
						height: 48px;
						border-radius: 24px;
					}

					.inputView {
						width: 132px;
						float: left;
						font-size: 14px;
					}

					i {
						color: #aaaaaa;
						vertical-align: sub;
						padding-left: 6px;
					}
				}

				.addImage {
					display: inline-block;
					float: right;
					width: 48px;
					height: 48px;
					border-radius: 24px;
					overflow: hidden;
					text-align: center;
					position: relative;
					border: 1px solid #cccccc;

					img {
						width: 96px;
						height: 96px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.footer {
			margin: 18px 0;
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #3e73fb;
			border-radius: 18px;
			color: #ffffff;
			font-size: 16px;
		}
	}

	::v-deep .itemValue .el-input__inner {
		border: none;
		border-radius: 0px;
		text-align: right;
		padding: 0;
	}
</style>
