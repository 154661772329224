/** mixin数据规则

 baseData: {
    tenantId: [],
  },
 baseOptions: {
    tenantOptions: [
      {
        name: '全部科室',
        id: ''
      }
    ],
  }
 */

export default {
  data() {
    return {
      tenantTreeList: [],
      defaultTenant: [{
        title: '全部科室',
        id: ''
      }]
    }
  },
  computed: {},
  mounted() {
    this.getOrg()
  },
  methods: {
    // 获取子机构科室
    getOrg: function() {
      let getData = () => {
        return new Promise(resolve => {
          let orgInfo = this.$store.getters.orgInfo
          if (orgInfo.length > 0) {
            let selectList = []
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < orgInfo.length; i++) {
              let org = orgInfo[i]
              if (org.id == tenantId) {
                selectList.push(org)
                break
              }
            }
            resolve({ list: orgInfo, selectList: selectList })
            return
          }

          this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
            let jsonStr = JSON.stringify(res.data)
            jsonStr = jsonStr.replace(/title/g, 'name')
            jsonStr = jsonStr.replace(/child/g, 'children')

            let list = []
            let selectList = []

            try {
              list = JSON.parse(jsonStr)
              let userInfo = this.$store.getters.userInfo
              let tenantId = userInfo.tenantId
              for (let i = 0; i < list.length; i++) {
                let org = list[i]
                if (org.id == tenantId) {
                  selectList.push(org)
                  break
                }
              }
            } catch (e) {

            }
            this.$store.commit('setOrgInfo', list)
            resolve({ list: this.$store.getters.orgInfo, selectList: selectList })
          }).catch(e => {
            resolve({ list: [], selectList: [] })
          })
        })
      }

      getData().then(res => {
        this.tenantTreeList = res.list
        this.baseOptions.tenantOptions = res.selectList
        this.$nextTick(() => {
          if (this.$valueIsExist(this.$refs, 'filter') == false) {
            return;
          }
          this.$refs['filter'].searchEvent()
        })
      })
    },
    getListTreeTenantAuthority() {
      this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
        this.tenantTreeList = res.data
      }).catch(e => {

      })
    }
  }
}
